import $ from "jquery";

// Local Parameters
let currentUrlHash;

// Paginated Panel Link's Click Listener
function paginatedPanelClickListener(event) {
  // Prevent Default
  event.preventDefault();

  // Check if the Link is Disabled or Active (ie The Current View)
  if ($(event.currentTarget).parent().hasClass("disabled") || $(event.currentTarget).parent().hasClass("active")) {
    // The Link is Disabled or Active
    return;
  }

  // Get the Link URL
  const url = $(event.currentTarget).attr("href");

  // Change the Hash
  currentUrlHash = url.substring(url.lastIndexOf("?page=") + 6);
  window.location.hash = currentUrlHash;

  // Get the New Panel Content
  $.get($(event.currentTarget).attr("href"), updatePaginatedPanelContent);
}

// Hash Change Listener
function hashChangeListener() {
  // Check if there is a non-empty Hash
  if (window.location.hash === "" || window.location.hash === "#") {
    // There is no JavaScript Pagination
    // Don't Reload Content
    return;
  }

  // Check if the URL Hash Change is due to Browser Navigation
  if (currentUrlHash === window.location.hash.replace("#", "")) {
    // The Current Hash is the Same than the Page
    // Don't Reload Content
    return;
  }

  // Get the New Panel Content
  $.get(window.location.href.replace(/page=[0-9]+#/, "page="), updatePaginatedPanelContent);
}

// Function to Update the Paginated Panel Content
function updatePaginatedPanelContent(panelContent) {
  // Replace the Panel Content
  $(".js-paginated-panel").html(panelContent);

  // Bind Again Click Listener
  $(".js-paginated-panel nav ul.pagination a").on("click", paginatedPanelClickListener);
}

// Initialize Function
function initializeAUMPagination() {
  // Check if there is an JS Paginated Panel on the Page
  if ($(".js-paginated-panel").length === 0) {
    // Do Initialize the  Functionnality
    return;
  }

  // Listen Pagination Click Events
  $(".js-paginated-panel nav ul.pagination a").on("click", paginatedPanelClickListener);

  // Bind an event to window.onhashchange that, when the hash changes, gets the
  $(window).on("hashchange.aum.pagination", hashChangeListener);

  // Since the event is only triggered when the hash changes, we need to trigger
  // the event now, to handle the hash the page may have loaded with.
  $(window).trigger("hashchange");
}

export { initializeAUMPagination };
