import $ from "jquery";
import Cookies from "js-cookie";

// Detect if the Browser Support HTML5 Local Storage
const localStorageSupport = (function () {
  const mod = "localStorageTest";
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (_err) {
    return false;
  }
})();

// Store an Object with the Associated Key
function storeJson(key, json) {
  if (localStorageSupport) {
    localStorage.setItem(key, JSON.stringify(json));
  } else {
    Cookies.set(key, JSON.stringify(json), { expires: 28, domain: ".adopteunemarque.com", path: "/" });
  }
}

// Retrieve the Stored JSON with the Associated Key
function retrieveJson(key) {
  let strJson;
  if (localStorageSupport) {
    strJson = localStorage.getItem(key);
  } else {
    strJson = Cookies.get(key);
  }
  if (strJson === undefined || strJson === null) {
    return null;
  } else {
    return JSON.parse(strJson);
  }
}

// Drop the Object with the Given Key
function dropJson(key) {
  if (localStorageSupport) {
    localStorage.removeItem(key);
  } else {
    Cookies.remove(key, { domain: ".adopteunemarque.com", path: "/" });
  }
}

// Helper to Save Page Current Form
function savePageForm() {
  // Get Form Inputs/TextAreas
  const savedForm = {};
  $("form input, form textarea").each(function (index, element) {
    // Save the Input/TextArea Value
    savedForm[$(element).attr("id")] = $(element).val();
  });

  // Save the Form
  storeJson("savedForm", savedForm);
}

// Initialize Function
function initializeAUMStorage() {
  // Check if there is a Saved Form
  const savedForm = retrieveJson("savedForm");
  if (savedForm !== null) {
    // Iterate over Keys
    $.each(savedForm, function (key, value) {
      // Restore the Input/TextArea Value
      if ($("#" + key).hasClass("rating")) {
        $("#" + key).rating("update", value);
      } else {
        $("#" + key).val(value);
      }
    });

    // Drop the Saved Form
    dropJson("savedForm");
  }
}

export { initializeAUMStorage, retrieveJson, savePageForm, storeJson };
