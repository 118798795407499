/* globals google:true */
import $ from "jquery";
import { getNearestPrices } from "./prices";
import { storeJson, retrieveJson } from "./storage";

// Local Parameters
const autocompleteForms = [];
let geocoder;
const defaultLocation = { address: "Place de l'Hôtel de Ville, Paris, France", lat: 48.8575215, lng: 2.349604 };
let previousPlace;

// Function for Updating Prices & Offers
function updatePricesAndOffers(address, latitude, longitude, callback) {
  // Save Location
  storeJson("location", {
    address,
    latitude,
    longitude,
  });

  // Get Nearest Prices
  getNearestPrices(address, latitude, longitude, callback);

  // Update all Input with the Address
  $(".js-input-location").each(function (index, input) {
    $(input).val(address);
  });
}

// Callback to Listen Place Changed Events on Autocomplete Forms
function onPlaceChanged() {
  $.each(autocompleteForms, function (index, form) {
    const place = form.getPlace();
    if (
      place !== undefined &&
      (previousPlace === undefined || previousPlace.formatted_address !== place.formatted_address)
    ) {
      // Update the Place
      previousPlace = place;

      // Update Prices & Offers
      updatePricesAndOffers(place.formatted_address, place.geometry.location.lat(), place.geometry.location.lng());
    }
  });
}

// Function for Asking the User Geolocation
function getUserGeolocation() {
  // Test if the Geolocation API is Available
  if (!navigator.geolocation) {
    // Do something for users without Geolocation : Display Shops Nearest Paris
    // Update Prices & Offers
    updatePricesAndOffers(defaultLocation.address, defaultLocation.lat, defaultLocation.lng);
    return;
  }

  // Ask the Browser Geolocation
  const userGeolocationTimeout = setTimeout(function () {
    // The User don't Allow Geolocation : Display Shops Nearest Paris
    updatePricesAndOffers(defaultLocation.address, defaultLocation.lat, defaultLocation.lng);
  }, 10000);
  navigator.geolocation.getCurrentPosition(
    function (position) {
      // Clear Timeout
      clearTimeout(userGeolocationTimeout);

      // Call the Google Geocoder Service
      geocoder.geocode(
        {
          location: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        },
        function (results, status) {
          // Check the Result
          if (status === google.maps.GeocoderStatus.OK && results[0] !== undefined) {
            // Display Nearest Shops
            updatePricesAndOffers(results[0].formatted_address, position.coords.latitude, position.coords.longitude);
          } else {
            // Display Nearest Shops
            updatePricesAndOffers("", position.coords.latitude, position.coords.longitude);
          }
        }
      );
    },
    function () {
      // Clear Timeout
      clearTimeout(userGeolocationTimeout);

      // An Error Occured in the Geolocation Process : Display Shops Nearest Paris
      updatePricesAndOffers(defaultLocation.address, defaultLocation.lat, defaultLocation.lng);
    },
    { timeout: 5000 }
  );
}

// Initialize Function
function initializeAUMLocation() {
  // Get Autocompletes Inputs
  const autocompleteInputs = $(".js-input-location");
  // Create Autocomplete Options
  const autocompleteOptions = {
    types: ["geocode"],
    componentRestrictions: { country: "fr" },
  };
  // Get Previous User Location
  const location = retrieveJson("location");
  // Init Callback
  let initCallback;

  // Create Autocomplete Forms
  autocompleteInputs.each(function (index, htmlElement) {
    const autocomplete = new google.maps.places.Autocomplete(htmlElement, autocompleteOptions);
    autocomplete.addListener("place_changed", onPlaceChanged);
    autocompleteForms.push(autocomplete);
  });

  // Create the Geocoder Service
  geocoder = new google.maps.Geocoder();

  // Test if we already have the User Location
  if (location !== null) {
    // Get Nearest Prices
    updatePricesAndOffers(location.address, location.latitude, location.longitude, initCallback);
  } else {
    // Request GPS Location to Create the Around Map
    getUserGeolocation();
  }

  // Test if the Geolocation API is Available
  if (navigator.geolocation) {
    // Activate the Location Button
    $(".location-form > .input-group-text").css("cursor", "pointer");

    // Listen Click Events on the Location Button
    $(".location-form > .input-group-text").on("click", getUserGeolocation);
  }
}

export { initializeAUMLocation };
