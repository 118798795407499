import $ from "jquery";
import "@popperjs/core";
import * as bootstrap from "bootstrap";

// Import our modules
import { initializeAUMAnalytics } from "./analytics.js";
import { initializeAUMContactForm } from "./contact.js";
import { initializeAUMLocation } from "./location.js";
import { initializeAUMPagination } from "./pagination.js";
import { initializeAUMPrices } from "./prices.js";
import { initializeAUMStorage } from "./storage.js";

// Attach jQuery & bootstrap to the window
window.$ = $;
window.jQuery = $;
window.bootstrap = bootstrap;

// Functions to Wait for Google Maps APIs Loading
const waitLocationAndPrices = function (callback) {
  // Check if Google Maps APIs are Loaded
  if (window.canLoadPricesAndLocationModules === true) {
    // Call the Callback
    callback();
  } else {
    // Wait 50ms more
    setTimeout(waitLocationAndPrices, 50, callback);
  }
};
function initializeLocationAndPrices() {
  // Load Application Modules
  initializeAUMLocation();
  initializeAUMPrices();
}

$(function () {
  "use strict";

  // Add a js class to the body
  $(document.body).addClass("js");

  // Load Application Modules
  initializeAUMAnalytics();
  initializeAUMStorage();
  initializeAUMContactForm();
  initializeAUMPagination();

  // Check if we have to Initialize the AUMLocation & AUMPrices Modules
  if (window.canLoadPricesAndLocationModules !== undefined) {
    waitLocationAndPrices(initializeLocationAndPrices);
  }
});
