import $ from "jquery";

// Function to Send a Contact Form
function sendContactForm(event) {
  // Prevent Default
  event.preventDefault();

  // Get Inputs
  const form = {};
  $(".js-form-contact input, .js-form-contact textarea").each(function (index, element) {
    form[$(element).attr("id")] = $(element).val();
  });

  // Post Message
  $.post("//adopteunemarque.com/contact/", form, function (contactResultModal) {
    // Add the Modal to the DOM
    $("body").append(contactResultModal);

    // Show the Modal
    $("#contactModal").modal("show");

    // Auto Close the Modal after 5s
    setTimeout(function () {
      // Close the Modal
      $("#contactModal").modal("hide");
    }, 3000);

    // Bind Close Event Listener
    $("#contactModal").on("hidden.bs.modal", function () {
      // Reload the Page
      window.location.reload(true);
    });
  });
}

// Contact Form Changes Listener
function listenContactFormChanges() {
  // Check if all Fields are Set
  let incorrectField = false;
  $(".js-form-contact input, .js-form-contact textarea").each(function (index, element) {
    if ($(element).val().trim() === "") {
      incorrectField = true;
    }
  });

  // Activate/Deactivate the "Send Contact Form" Button
  if (incorrectField) {
    $(".js-send-contact").addClass("disabled");
    $(".js-send-contact").attr("disabled", "disabled");
  } else {
    $(".js-send-contact").removeClass("disabled");
    $(".js-send-contact").removeAttr("disabled");
  }
}

// Initialize Function
function initializeAUMContactForm() {
  // Bind Send Contact Form Click Listener
  $(".js-form-contact").on("submit", sendContactForm);

  // Listen Contact Form Changes
  $(".js-form-contact input, .js-form-contact textarea").on("keyup", listenContactFormChanges);
}

export { initializeAUMContactForm };
