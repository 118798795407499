/* globals ga:true */
import $ from "jquery";

/*
 * DOCUMENTATION : Analytics Event Tracker
 * Bind Event Listener to Click Events on the Body.
 * For each Events the Script Lookup for Elements with the "js-analytics" class in Target & Target's Parents.
 * The event is set-up by data-ga-* fields (ex : data-ga-category for the category field).
 * Required fields are category and action.
 * The value field must be an Integer and required a label field.
 */
// Initialize Function
function initializeAUMAnalytics() {
  $("body").on("click", function (event) {
    /*
     * Get Elements : Target if it has the js-analytics class & Target's Parents with the js-analytics class
     */
    const elements = [];
    const target = $(event.target);
    const targetParents = target.parents(".js-analytics");
    // Check if the Target has the Analytics Marker
    if (target.hasClass("js-analytics")) {
      // Add the Target to the List
      elements.push(target);
    }
    // Add Parents with the Analytics Marker to the List
    targetParents.each(function (idx, el) {
      // Add the Parent to the List
      elements.push($(el));
    });

    /*
     * Process Elements
     */
    $.each(elements, function (idx, el) {
      // Get the Analytics Event Data
      const gaEventData = el.data();

      // Log the Event
      console.log(gaEventData);

      // Test if Analytics is Loaded
      if (typeof ga === "undefined") {
        // Missing Analytics Library : Exit
        console.log("Analytics Library is not loaded !");
        return;
      }

      // Test if there is all required fields
      if (gaEventData.gaCategory === undefined || gaEventData.gaAction === undefined) {
        // Missing Required Fields : Exit
        console.log("Missing Required Fields for the Analytics Event !");
        return;
      }

      // Test if there is a Value field
      if (gaEventData.gaLabel !== undefined && gaEventData.gaValue !== undefined) {
        // Send the Event
        ga("send", "event", gaEventData.gaCategory, gaEventData.gaAction, gaEventData.gaLabel, gaEventData.gaValue);
        return;
      }

      // Test if there is a Label field
      if (gaEventData.gaLabel !== undefined) {
        // Send the Event
        ga("send", "event", gaEventData.gaCategory, gaEventData.gaAction, gaEventData.gaLabel);
        return;
      }

      // Send the Event
      ga("send", "event", gaEventData.gaCategory, gaEventData.gaAction);
    });
  });
}

export { initializeAUMAnalytics };
