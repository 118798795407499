/* globals productUrlFragment:true, google:true */
import $ from "jquery";

// Local Parameters
let gMaps;
let gMapsMarkers;
let gMapsInfowindows;
// Affiliation
const affLinks = {
  "Auchan Drive": "https://tracking.publicidees.com/clic.php?promoid=66945&progid=2350&partid=48562",
  Chronodrive: "https://tracking.publicidees.com/clic.php?promoid=117954&progid=4272&partid=48562",
  "Casino Drive": "https://track.effiliation.com/servlet/effi.click?id_compteur=22526198",
  "Mes Courses Casino": "https://track.effiliation.com/servlet/effi.click?id_compteur=22526198",
  Monoprix: "https://action.metaffiliation.com/trk.php?mclic=P510467571FD92193",
};

// Function to Load Prices
function getNearestPrices(address, latitude, longitude, callback) {
  // Get Prices
  $.post(
    "//adopteunemarque.com/prices/" + productUrlFragment,
    { address, lat: latitude, lng: longitude },
    function (data) {
      // Parse the HTML Response
      const htmlElements = $($.parseHTML(data));

      // Paste Elements into the DOM
      $(".js-prices-list").html(htmlElements.find(".js-table-prices"));
      $(".js-offers-list").html(htmlElements.find(".js-table-offers"));
      $(".js-identicals-list").html(htmlElements.find(".js-panel-identicals"));

      // Paste Counters into the DOM
      $(".js-nb-prices").html($(".js-table-prices > tbody > tr").length);
      $(".js-nb-offers").html($(".js-table-offers > tbody > tr").length);

      // Display the Google Maps Map with Markers
      displayGoogleMapsMap(htmlElements.find(".js-markers > .js-marker"), latitude, longitude);

      // Call the Callback
      if (typeof callback === "function") {
        callback();
      }
    }
  );
}

// Create Google Maps Options with the Given Latitude & Longitude
function createGoogleMapsOptions(latitude, longitude) {
  return {
    center: new google.maps.LatLng(latitude, longitude),
    zoom: 14,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    draggable: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
  };
}

// Create Google Maps Marker with the Given Parameters
function createGoogleMapsMarker(markerParameters) {
  return {
    animation: google.maps.Animation.DROP,
    position: new google.maps.LatLng(markerParameters.latitude, markerParameters.longitude),
    clickable: markerParameters.clickable,
    draggable: false,
    title: markerParameters.title,
    icon: {
      url: "//adopteunemarque.com/dist/img/markers/" + markerParameters.image,
      scaledSize: new google.maps.Size(53, 80),
      size: new google.maps.Size(105, 159),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(27, 80),
    },
    shape: {
      coords: [27, 80, 18, 49, 5, 38, 5, 13, 18, 3, 36, 3, 50, 14, 50, 37, 37, 48],
      type: "poly",
    },
    map: markerParameters.gMaps,
  };
}

// Function to Display the Google Maps Map with Markers
function displayGoogleMapsMap(markers, latitude, longitude) {
  // Delay until Google Maps API is loaded
  if (typeof google === "undefined" || google.maps === undefined || google.maps.Map === undefined) {
    setTimeout(displayGoogleMapsMap, 1000);
    return;
  }

  // Show the Map with Prices
  const bounds = new google.maps.LatLngBounds();
  let i = 0;

  // Test if there is already a Map
  if (gMaps !== undefined) {
    // Close all Infowindow
    $.each(gMapsInfowindows, function (idx, gmapsIW) {
      gmapsIW.close();
    });
    // Remove all Marker with Listeners
    $.each(gMapsMarkers, function (idx, gmapsM) {
      google.maps.event.clearListeners(gmapsM, "click");
      gmapsM.setMap(null);
    });

    // Change the Map Options
    gMaps.setOptions(createGoogleMapsOptions(latitude, longitude));
  } else {
    // Display the Map
    gMaps = new google.maps.Map($("#shops-map").get(0), createGoogleMapsOptions(latitude, longitude));

    // Add GMaps Go Shop Event Listener
    google.maps.event.addListener(window, "gmaps:go:shop", function (shopID, retailerName) {
      if (affLinks[retailerName]) {
        window.open(affLinks[retailerName], "_blank");
      } else {
        window.open("https://lebondrive.fr/chargerDrive/" + shopID, "_blank");
      }
    });
  }

  // Reset Variables
  gMapsMarkers = [];
  gMapsInfowindows = [];

  // Add Current Location Marker
  gMapsMarkers.push(
    new google.maps.Marker(
      createGoogleMapsMarker({
        latitude,
        longitude,
        clickable: false,
        title: "Votre Position",
        image: "marker_location.png",
        gMaps,
      })
    )
  );

  // Show Store's Markers
  $.each(markers, function (index, value) {
    // Create Marker
    const marker = new google.maps.Marker(
      createGoogleMapsMarker({
        latitude: $(value).data("lat"),
        longitude: $(value).data("lng"),
        clickable: true,
        title: $(value).data("title"),
        image: $(value).data("img"),
        gMaps,
      })
    );

    // Create Infowindow
    const infowindow = new google.maps.InfoWindow({ content: value });

    // Add Click Listener to Show Popup
    google.maps.event.addListener(marker, "click", function () {
      // Close all previous Infowindow
      $.each(gMapsInfowindows, function (idxGmapsIW, gmapsIW) {
        gmapsIW.close();
      });

      // Open New Infowindow
      infowindow.open(gMaps, this);
    });

    // Add Marker and Infowindow
    gMapsMarkers.push(marker);
    gMapsInfowindows.push(infowindow);

    // Test if we have already 10 shops
    if (i < 10) {
      // Increase the bounds to take the current shop
      bounds.extend(marker.getPosition());

      // Increment shop counter
      i += 1;
    }
  });

  // Fit these bounds to the map if there is Shops
  if (markers.length > 0) {
    gMaps.fitBounds(bounds);
  }
}

// Initialize Function
function initializeAUMPrices() {
  // Listen Click Events on Call To Action Buttons
  $(".js-show-prices").on("click", function () {
    // Show Tab
    $('.js-around-panel a[href="#prices-list"]').tab("show");

    // Scroll To Tab
    $("html, body").animate(
      {
        scrollTop: $(".js-around-panel").offset().top,
      },
      1000
    );
  });
  $(".js-show-offers").on("click", function () {
    // Show Tab
    $('.js-around-panel a[href="#offers-list"]').tab("show");

    // Scroll To Tab
    $("html, body").animate(
      {
        scrollTop: $(".js-around-panel").offset().top,
      },
      1000
    );
  });
}

export { getNearestPrices, initializeAUMPrices };
